var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "default-page" },
    _vm._l(
      _vm.sections.filter(function (s) {
        return s.enabled
      }),
      function (section, key) {
        return _c("SectionSelector", {
          directives: [
            {
              name: "scroll",
              rawName: "v-scroll",
              value: _vm.handleScroll,
              expression: "handleScroll",
            },
          ],
          key: key,
          ref: "sections",
          refInFor: true,
          attrs: {
            enabled: section.enabled,
            hash: section.hashAnchor,
            variant: section.variant,
            data: section.data,
            "multi-project": section.multi_project,
            section: section.type,
            "order-no": section.order_no,
          },
        })
      }
    ),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }